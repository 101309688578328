import { Level } from '@nsalaun/ng-logger';

import { ApplicationNamesEnum, EnvironmentModel, StagesEnum } from '@svg/environment';

export const ENVIRONMENT: EnvironmentModel = {
	apiInfix: 'api.testing.mysvg.de',
	applicationName: ApplicationNamesEnum.mySVG,
	clientId: 'mySVG',
	enabledUpdateServiceWorker: true,
	logLevel: Level.DEBUG,
	name: StagesEnum.TESTING,
	scope: 'openid',
	sentry: {
		url: null,
		tracesSampleRate: 0,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 0,
	},
	tokenIssuer: 'https://testing.svg-auth.de/realms/mysvg',
};
